import { makeStyles } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import logo from '../../assets/images/onboarding-logo.svg'

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'grid',
        gridAutoRows: 'min-content',
        gap: theme.spacing(4),
        justifyItems: 'center',
        alignItems: 'center',
        maxWidth: 350,
        paddingTop: theme.spacing(23)
    },
    logo: {
        background: `url(${logo}) center center no-repeat`,
        backgroundSize: 'contain',
        height: 167,
        width: 194
    },
    content: {
        display: 'grid',
        gridAutoFlow: 'row',
        gap: theme.spacing(),
        justifyItems: 'center',
        textAlign: 'center'
    },
    title: {
        margin: 0
    },
    description: {
        margin: 0,
        lineHeight: '150%'
    }
}))

export const OnboardingStart: React.FC = () => {
    const { t } = useTranslation(['common', 'matrices'])
    const classes = useStyles()

    return (
        <div className={classes.container}>
            <div className={classes.logo} />

            <div className={classes.content}>
                <h2 className={classes.title}>
                    {t('matrices:onboarding.firstStep.title')}
                </h2>

                <p className={classes.description}>
                    {t('matrices:onboarding.firstStep.description')}
                </p>
            </div>
        </div>
    )
}

export default OnboardingStart
